import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faShare } from "@fortawesome/free-solid-svg-icons";

import { customPost } from "services/api/promises";
import LessonHandler from "./lessonHandler";

import styles from "./style.module.css";

//for dev
import { lessonList as pendingTmp } from "./debugTemplate";

export const locStats = {
  PEDNING: "pending",
  MATCHED: "match_found", //means request sent and user has payed
  NOBBED: "no_match_found",
};

function ResetOptions({ quit, redo }) {
  return (
    <div
      className={`${styles.redoOptions} d-flex flex-column justify-content-around align-items-center`}
    >
      <button className="btn accept-theme" onClick={quit}>
        {" "}
        <FontAwesomeIcon icon={faShare} size="lg"></FontAwesomeIcon>{" "}
      </button>
      <button className="btn reject-theme" onClick={redo}>
        {" "}
        <FontAwesomeIcon icon={faRedo} size="lg"></FontAwesomeIcon>{" "}
      </button>
    </div>
  );
}

const submitRejects = async lessons => {
  for (let lesson of lessons) {
    for (let application of lesson.applications) {
      if (lesson.localStatus === locStats.NOBBED) {
        await customPost(`/api/lesson/student/respond/${application.id}`, { accept: false }, {});
      }
    }
  }
};
const QUIT = async lessons => {
  await submitRejects(lessons);
  window.location.reload();
};

const Empty = ({ lessons }) => {
  React.useEffect(() => {
    QUIT(lessons);
  }, [lessons]);
  return <div className="f2 big-info">Inga besvarade lektionsförfrågningar.</div>;
};

function Swipe({ pendingLessons = pendingTmp, quit = () => {} }) {
  const [lessons, setLessons] = useState([]);
  const [resets, setResets] = useState(0);
  const exit = () => {
    submitRejects(lessons);
    quit();
  };
  useEffect(() => {
    if (
      lessons.filter(lesson => lesson.localStatus === locStats.MATCHED).length ===
      pendingLessons.length
    )
      quit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessons]);
  useEffect(() => {
    let newLess = [];
    const matchedIDs = new Set();
    for (let lesson of pendingLessons) {
      if (lesson.localStatus === locStats.MATCHED) matchedIDs.add(lesson.id);
    }
    for (let i = 0; i < pendingLessons.length; i++) {
      let lesson = { ...pendingLessons[i] };
      lesson["localStatus"] = locStats.PEDNING;
      lesson.update = newStat => {
        setLessons(prev => {
          const newVal = [...prev];
          for (let newLess of newVal) {
            if (newLess.id === lesson.id) {
              newLess.localStatus = newStat;
            }
          }
          return newVal;
        });
      };
      if (!matchedIDs.has(lesson.id)) newLess.push(lesson);
    }
    setLessons(newLess);
  }, [resets, pendingLessons]);
  if (lessons.length === 0) return <Empty></Empty>;
  const rels = lessons.filter(lesson => lesson.localStatus === locStats.PEDNING);
  if (rels.length > 0) {
    let lesson = rels[0];
    return (
      <LessonHandler
        lesson={lesson}
        match={() => {
          lesson.update(locStats.MATCHED);
        }}
        remove={() => lesson.update(locStats.NOBBED)}
      ></LessonHandler>
    );
  }
  if (lessons.filter(lesson => lesson.localStatus === locStats.NOBBED).length > 0)
    return (
      <ResetOptions redo={() => setResets(resets + 1)} quit={() => QUIT(lessons)}></ResetOptions>
    );
  if (
    lessons.filter(lesson => lesson.localStatus === locStats.MATCHED).length ===
    pendingLessons.length
  )
    return <Empty lessons={pendingLessons}></Empty>;
}

export default Swipe;
