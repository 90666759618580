import { customPost } from "services/api/promises";
const localVapidKey =
  process.env.REACT_APP_PUBLIC_VAPID_KEY ||
  "BBDu4hnH2R-I0mZRUAE6AyQ5vji9Z1qDWC-GiCFL2uaMvJDE1iaEl8Ioiqdar8LXBzm2hZCTQN6ualybCv9MIBM";
const convertedVapidKey = urlBase64ToUint8Array(localVapidKey);
console.log("converted key", convertedVapidKey);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const MAX_ATTEMPTS = 10;
async function sendSubscription(subscription, counter = 0) {
  if (counter >= MAX_ATTEMPTS) return false;
  try {
    const res = await customPost("/api/profile/notis/subscribe", subscription, {});
    console.log(res.status);
    if (res.status !== 200 && res.status !== 201) {
      console.log("Error with subscribe post, retrying in 1s...");
      throw new Error("Error with subscribe post, retrying ...");
    }
  } catch (err) {
    setTimeout(() => sendSubscription(subscription, counter + 1), 1000);
  }
}

export async function subscribeUser() {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          console.log("Push manager unavailable.");
          return;
        }

        return registration.pushManager.getSubscription().then(function (existedSubscription) {
          if (existedSubscription === null) {
            console.log("No subscription detected, make a request.");
            return registration.pushManager
              .subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true,
              })
              .then(function (newSubscription) {
                console.log(
                  "New subscription added.",
                  newSubscription,
                  JSON.stringify(newSubscription),
                  newSubscription.toJSON(),
                );
                return sendSubscription(newSubscription);
              })
              .catch(function (e) {
                if (Notification.permission !== "granted") {
                  console.log("Permission was not granted.");
                } else {
                  console.error("An error ocurred during the subscription process.", e);
                }
              });
          } else {
            console.log(
              "Existed subscription detected.",
              existedSubscription,
              existedSubscription.toJSON(),
              JSON.stringify(existedSubscription),
            );
            return sendSubscription(existedSubscription);
          }
        });
      })
      .catch(function (e) {
        console.error("An error ocurred during Service Worker registration.", e);
      });
  }
}

export async function unsubscribeUser() {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker.ready.then(registration => {
      return registration.pushManager.getSubscription().then(subs => {
        console.log(subs);
        if (subs === null) {
          console.log("no sub found");
          return;
        }
        return subs
          .unsubscribe()
          .then(() => console.log("Unsubscribed"))
          .catch(() => console.log("failed to unsubscribe"));
      });
    });
  }
}

export async function renew_sub() {
  await fetch("/api/debug/reset/subs");
  await unsubscribeUser();
  await subscribeUser();
}
