import { Button, Typography } from "@mui/material";
import { studentLinks } from "components/navigation/student-navigation";
import { useFetch } from "hooks/fetch-hooks";
import { useNavigate } from "react-router-dom";
import Base from "./base";
import Loading from "components/Loading";
import React from "react";
import styles from "./schedule.module.css";

const Empty = () => {
  const navigate = useNavigate();
  return (
    <div className="big-info">
      <Typography variant="body1">Inga Lektioner?</Typography>
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          navigate(studentLinks.BOOK);
        }}
      >
        Boka nu
      </Button>
    </div>
  );
};

function StudentSchedule({ debugData }) {
  const data = useFetch("/api/lesson/student/scheduled", { authorize: true });
  if (!data?.isLoading === false) return <Loading></Loading>;
  //For debug ONLY Remove in production'
  // !!! ---===---
  if (debugData && data?.data.length === 0)
    return (
      <section className={`${styles.schedule}`}>
        <Base data={debugData} isLoading={false}></Base>
      </section>
    );
  if (data?.data.length === 0) return <Empty></Empty>;
  return (
    <section className={`${styles.schedule}`}>
      <Base {...data}></Base>
    </section>
  );
}

export default StudentSchedule;
