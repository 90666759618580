import { customPost } from "services/api/promises";
import { useFetch } from "hooks/fetch-hooks";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import React from "react";

function Swish() {
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      const res = await fetch("/api/debug/swish");
      const data = await res.json();
      const { PaymentRequestToken: token } = data;
      window.location = `swish://paymentrequest?token=${token}&callbackurl=192.168.1.109/home`;
    })();
  });
  return <Loading />;
}

export default Swish;
