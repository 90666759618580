import React from "react";

import { useLogin } from "hooks/fetch-hooks";

import AdminApp from "admin-pages/app";
import Auth from "components/auth";
import Loading from "components/Loading";
import StudentApp from "student-pages/app";
import TeacherApp from "teacher-pages/app";
import withMuiTheme from "hocs/WithMuiTheme";

export const LoginContext = React.createContext(null);

const App = () => {
  const { signed_in, setSigned_in, role, profile, setRole, setProfile, refreshData } = useLogin();
  const [studentView, setStudentView] = React.useState(false); //Setting to allow teachers to be in student view

  if (signed_in === false)
    return <Auth setSigned_in={setSigned_in} refreshData={refreshData}></Auth>;

  if (signed_in && (role === "student" || studentView)) {
    return (
      <LoginContext.Provider
        value={{ signed_in, setSigned_in, role, profile, setRole, setProfile }}
      >
        <StudentApp></StudentApp>
      </LoginContext.Provider>
    );
  }
  if (signed_in && role === "teacher") {
    return (
      <LoginContext.Provider
        value={{
          signed_in,
          setSigned_in,
          role,
          profile,
          setProfile,
          setStudentView,
        }}
      >
        <TeacherApp></TeacherApp>
      </LoginContext.Provider>
    );
  }
  if (signed_in && role === "admin") {
    return (
      <LoginContext.Provider
        value={{
          signed_in,
          setSigned_in,
          role,
          profile,
          setProfile,
          setStudentView,
        }}
      >
        <AdminApp></AdminApp>
      </LoginContext.Provider>
    );
  }
  return (
    <div className="fullscreen-container">
      <Loading></Loading>
    </div>
  );
};

export default withMuiTheme(App);
