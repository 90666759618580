import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animated, useSpring } from "react-spring";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDrag } from "@use-gesture/react";

import { CustomRating } from "components/RateLesson";

import styles from "./style.module.css";

const DIST_TILL_DONE = 250;
const MAX_ANGLE = 70; //deg
const RATIO_AT_FULL = 0.8;
const ACC_REJ_ICONS = [faCheck, faTimes];

//Animation details
const dt = 30; //ms
const vx = 3; //px / ms
const T = 1500; //ms animation period

const left = {
  backgroundColor: "#f00",
  color: "#fff",
  //bg: `linear-gradient(120deg, #f093fb 0%, #f5576c 100%)`,
  // justifySelf: 'end',
};
const right = {
  backgroundColor: "#0f0",
  color: "#fff",
  //bg: `linear-gradient(120deg, #96fbc4 0%, #f9f586 100%)`,
  /*justifySelf: 'start,'*/
};

function SwipeCard({ accept = () => {}, reject = () => {}, application }) {
  const [bgIconIndex, setIconIndex] = React.useState(0);
  const [swiped, setSwiped] = React.useState(0); //-2(btn) -1 0 or 1 2 (btn)
  const [styleProps, api] = useSpring(() => ({
    x: 0,
    scale: 1,
    opacity: 0,
    rotate: 0, //deg
    ...right,
  }));
  const { x, bg, scale, justifySelf, opacity, color, backgroundColor, rotate } = styleProps;
  //Reset for every new card
  React.useEffect(() => {
    setSwiped(0);
    api.start({
      x: 0,
      scale: 1,
      opacity: 0,
      rotate: 0, //deg
      ...right,
    });
  }, [application, api]);
  React.useEffect(() => {
    if (swiped === 0) return;
    let x = Math.abs(swiped) === 2 ? 0 : swiped < 0 ? -DIST_TILL_DONE : DIST_TILL_DONE;
    if (swiped === -2) setIconIndex(0);
    let t = 0;
    const k = swiped < 0 ? -1 : 1;
    const I = setInterval(() => {
      api.start({
        x,
        scale: 1.1,
        opacity: Math.min(1, Math.abs(x * 0.001)),
        rotate: Math.max(
          Math.min(Math.floor((x * MAX_ANGLE * RATIO_AT_FULL) / DIST_TILL_DONE), MAX_ANGLE),
          -MAX_ANGLE,
        ),
        ...(x < 0 ? left : right),
      });
      x += vx * dt * k;
      t += dt;
      if (t >= T) {
        clearInterval(I);
      }
    }, 30);
    const R = setTimeout(() => {
      if (swiped < 0) reject();
      else accept();
    }, T);
    return () => {
      clearInterval(I);
      clearTimeout(R);
    };
  }, [swiped, api, reject, accept]);

  const bind = useDrag(({ active, movement: [x, y] }) => {
    if (active) {
      const newInd = x < 0 ? 0 : 1;
      setIconIndex(newInd);
    }
    if (Math.abs(x) > DIST_TILL_DONE) {
      const swipeRes = x < 0 ? -1 : 1;
      setSwiped(swipeRes);
      return;
    }
    api.start({
      x: active ? x : 0,
      scale: active ? 1.1 : 1,
      opacity: active ? Math.min(1, Math.abs(x * 0.001)) : 0,
      rotate: active
        ? Math.max(
            Math.min(Math.floor((x * MAX_ANGLE * RATIO_AT_FULL) / DIST_TILL_DONE), MAX_ANGLE),
            -MAX_ANGLE,
          )
        : 0,
      ...(x < 0 ? left : right),
      immediate: name => active && name === "x",
    });
  });
  //For debug ONLY
  const teacherName = "Lärare";
  const teacherRating = 1.0;
  return (
    <section>
      <animated.div
        {...bind()}
        className={`${styles.swipeCard}`}
        style={{ bg, touchAction: "none" }}
      >
        <animated.div className={`${styles.bg}`} style={{ opacity, color, backgroundColor }}>
          <div className="hello">
            {bgIconIndex === 0 ? (
              <FontAwesomeIcon icon={faTimes} size="10x"></FontAwesomeIcon>
            ) : (
              <FontAwesomeIcon icon={faCheck} size="10x"></FontAwesomeIcon>
            )}
          </div>
        </animated.div>
        <animated.div style={{ x, scale, rotate }}>
          <h2 className="text-center cap">{application?.teacher?.name || "Anonym Lärare"}</h2>
          <div className="row d-flex flex-column align-items-center">
            <div className={"col-md-8 "}>
              <img
                className={`img-fluid w-100`}
                src="https://images.unsplash.com/photo-1621864980675-4d7f27420a60?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                alt="Teacher"
              />
            </div>
            <div className="col-md-8">
              <p className="f2 cap">{application?.teacher?.name}</p>
              <CustomRating readOnly rating={0}></CustomRating>
              {teacherRating === null && <p>(Inte Betygsatt Än)</p>}
              <p className="f3">Kommer {}</p>
              <p className="f3"></p>
            </div>
            <div className="col-md-8 d-flex justify-content-between">
              <button
                className="btn btn-danger btn-reject"
                onClick={() => {
                  setSwiped(-2);
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>{" "}
              </button>
              <button
                className="btn btn-success btn-accept"
                onClick={() => {
                  setSwiped(2);
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>{" "}
              </button>
            </div>
          </div>
        </animated.div>
      </animated.div>
    </section>
  );
}

export default SwipeCard;
