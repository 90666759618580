import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { StaticDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import globals from "globalData";

// This component allows the user to select a date for their lesson
function LessonDateSelect({ lessonDate, setLessonDate }) {
  return (
    // A StaticDatePicker component is rendered, which displays a calendar to select a date
    <StaticDatePicker
      disablePast={true}
      disableHighlightToday={true}
      displayStaticWrapperAs="desktop"
      value={lessonDate}
      onChange={newValue => {
        setLessonDate(newValue);
      }}
      // The TextField component is passed as the renderInput prop, which allows the user to input a date manually
      renderInput={params => <TextField {...params} />}
    />
  );
}

function TimeBetweenSelect({
  earliestStartTime,
  setEarliestStartTime,
  latestStartTime,
  setLatestStartTime,
}) {
  return (
    <div className="startTimeWrapper justify-content-between">
      <Typography className="startTimeText">Starttid mellan</Typography>
      <MobileTimePicker
        value={earliestStartTime}
        ampm={false}
        maxTime={latestStartTime}
        InputProps={{
          disableUnderline: true,
        }}
        onChange={earliestStartTime => {
          setEarliestStartTime(earliestStartTime);
        }}
        renderInput={params => <TextField {...params} size="small" variant="standard"></TextField>}
      />

      <Typography className="startTimeText">&</Typography>

      <MobileTimePicker
        value={latestStartTime}
        minTime={earliestStartTime}
        ampm={false}
        InputProps={{
          disableUnderline: true,
        }}
        onChange={latestStartTime => {
          setLatestStartTime(latestStartTime);
        }}
        renderInput={params => <TextField {...params} size="small" variant="standard"></TextField>}
      />
    </div>
  );
}

function LessonLengthInput({ lessonMinutes, setLessonMinutes, lessonHours, setLessonHours }) {
  function safeUpdate(value, min, max, setter) {
    if (value === "") {
      setter("");
    } else if (min <= value && value <= max) {
      setter(Number(value));
    }
  }

  return (
    <div className="lessonLengthWrapper">
      <TextField
        type="number"
        variant="standard"
        className="numericInput"
        value={lessonHours}
        onChange={e => {
          safeUpdate(e.target.value, 1, 10, setLessonHours);
        }}
        InputProps={{
          disableUnderline: true,
          inputProps: {
            min: 1,
            max: 10,
          },
        }}
      />
      <Typography className="lessonLengthText">h</Typography>
      <div className="flex-grow-1" />
      <TextField
        type="number"
        variant="standard"
        className="numericInput"
        value={lessonMinutes}
        onChange={e => {
          safeUpdate(e.target.value, 0, 60, setLessonMinutes);
        }}
        InputProps={{
          disableUnderline: true,
          inputProps: {
            min: 0,
            max: 60,
          },
        }}
      />
      <Typography className="lessonLengthText">min</Typography>
    </div>
  );
}

function TotalSum({ lessonHours, lessonMinutes }) {
  return (
    <div className="d-flex justify-content-between">
      <Typography
        variant="h6"
        align="left"
        className="mt-3 w1"
        sx={{
          color: "var(--secondary-dark)",
        }}
      >
        Totalt:
      </Typography>
      <Typography
        variant="h6"
        align="left"
        className="mt-3 w1"
        sx={{
          color: "var(--secondary-dark)",
        }}
      >
        {`${Math.round(globals.price * (lessonHours * 60 + lessonMinutes))} kr`}
      </Typography>
    </div>
  );
}

export { LessonDateSelect, TimeBetweenSelect, LessonLengthInput, TotalSum };
