import { useParams } from "react-router-dom";
import React from "react";

import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";

import { customPost } from "services/api/promises";
import CustomContainer from "components/CustomContainer";

import styles from "./styles.module.css";

function ResetPassword() {
  const { code } = useParams();
  const [hasReset, setHasReset] = React.useState(false);
  const [entered, updateEntered] = React.useState({ password1: "", password2: "" });
  const handleChange = e => {
    updateEntered({
      ...entered,
      [e.target.name]: e.target.value,
    });
  };
  const doMatch = React.useCallback(() => {
    return entered.password1 === entered.password2;
  }, [entered]);
  const handleSubmit = e => {
    if (!doMatch()) return;
    customPost(
      `/api/profile/verify/password/reset/${code}`,
      { new_password: entered.password1 },
      { authorize: false },
    )
      .then(r => {
        if (r.status === 202) {
          setHasReset(true);
        }
      })
      .catch(err => alert(err));
  };
  return (
    <CustomContainer>
      <section className={`${styles.reset_cont}`}>
        <h1 className="f2 text-center">Återställ ditt lösenord</h1>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="6 col-centered">
              <form>
                <div>
                  <MDBInput
                    label="Lösenord"
                    icon="envelope"
                    group
                    type="password"
                    validate
                    name="password1"
                    onChange={handleChange}
                  />
                  <MDBInput
                    label="Upprepa Lösenord"
                    icon="lock"
                    group
                    type="password"
                    validate
                    name="password2"
                    onChange={handleChange}
                  />
                </div>
                {!doMatch() && (
                  <div className="errMsg text-center mb-3">Lösenorden matchar inte</div>
                )}
                <div className="text-center">
                  <MDBBtn onClick={handleSubmit}>Återställ</MDBBtn>
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </CustomContainer>
  );
}

export default ResetPassword;
