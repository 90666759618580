import { Route, Routes } from "react-router-dom";
import React from "react";

import { LoginContext } from "app";
import CustomContainer from "components/CustomContainer";
import Navigation, { adminLinks } from "components/navigation/admin-navigation";

import AuthTeach from "./AuthorizeTeach";
import CreatePromo from "./Promo";

// The App component is a functional component that displays the admin view of the app
function App() {
  const { setSigned_in } = React.useContext(LoginContext);
  return (
    <CustomContainer>
      <Navigation></Navigation>
      <div className="text-center">
        <h1>Det här är admin vy</h1>
      </div>
      <Routes>
        <Route path={adminLinks.AUTH} element={<AuthTeach></AuthTeach>}></Route>
        <Route path={adminLinks.PROMOS} element={<CreatePromo></CreatePromo>}></Route>
      </Routes>
      <div className="text-center" style={{ marginBottom: "4rem" }}>
        <button
          className="btn"
          onClick={_ => {
            localStorage.clear();
            setSigned_in(false);
          }}
        >
          Logga ut
        </button>
      </div>
    </CustomContainer>
  );
}

export default App;
