import PageTitle from "components/navigation/PageTitle";
import React from "react";

import { TopBarContext } from "student-pages/app";
import Schedule from "components/LessonDisplay/schedule/studentSchedule";
import styles from "./Home.module.css";
const tmpLessons = [
  {
    id: 0,
    subjects: ["Matte 1"],
    note: "string",
    length: 0,
    earliest_potential_start: "2021-12-12T15:01:45.748Z",
    latest_potential_start: "2021-12-12T23:01:45.748Z",
    start_time: "2021-12-12T23:23:45.748Z",
    status: "requested",
    position: {
      lat: 0,
      lng: 0,
      adress: "string",
      id: 0,
    },
    student: {
      id: 0,
      name: "string",
    },
    teacher: {
      id: 0,
      name: "Knut",
      rating: 0,
    },
    applications: [],
  },
  {
    id: 1,
    subjects: ["Matte 1"],
    note: "string",
    length: 0,
    earliest_potential_start: "2021-12-12T15:01:45.748Z",
    latest_potential_start: "2021-12-12T15:01:45.748Z",
    start_time: "2021-12-12T21:21:45.748Z",
    status: "requested",
    position: {
      lat: 0,
      lng: 0,
      adress: "string",
      id: 0,
    },
    student: {
      id: 0,
      name: "string",
    },
    teacher: {
      id: 0,
      name: "string",
      rating: 0,
    },
    applications: [],
  },
  {
    id: 0,
    subjects: ["Matte 1"],
    note: "string",
    length: 0,
    earliest_potential_start: "2021-12-12T15:01:45.748Z",
    latest_potential_start: "2021-12-12T23:01:45.748Z",
    start_time: "2021-12-12T23:23:45.748Z",
    status: "requested",
    position: {
      lat: 0,
      lng: 0,
      adress: "string",
      id: 0,
    },
    student: {
      id: 0,
      name: "string",
    },
    teacher: {
      id: 0,
      name: "string",
      rating: 0,
    },
    applications: [],
  },
  {
    id: 1,
    subjects: ["Matte 1"],
    note: "string",
    length: 0,
    earliest_potential_start: "2021-12-12T15:01:45.748Z",
    latest_potential_start: "2021-12-12T15:01:45.748Z",
    start_time: "2021-12-12T21:21:45.748Z",
    status: "requested",
    position: {
      lat: 0,
      lng: 0,
      adress: "string",
      id: 0,
    },
    student: {
      id: 0,
      name: "string",
    },
    teacher: {
      id: 0,
      name: "string",
      rating: 0,
    },
    applications: [],
  },
  {
    id: 0,
    subjects: ["Matte 1"],
    note: "string",
    length: 0,
    earliest_potential_start: "2021-12-12T15:01:45.748Z",
    latest_potential_start: "2021-12-12T23:01:45.748Z",
    start_time: "2021-12-12T23:23:45.748Z",
    status: "requested",
    position: {
      lat: 0,
      lng: 0,
      adress: "string",
      id: 0,
    },
    student: {
      id: 0,
      name: "string",
    },
    teacher: {
      id: 0,
      name: "string",
      rating: 0,
    },
    applications: [],
  },
  {
    id: 1,
    subjects: ["Matte 1"],
    note: "string",
    length: 0,
    earliest_potential_start: "2021-12-12T15:01:45.748Z",
    latest_potential_start: "2021-12-12T15:01:45.748Z",
    start_time: "2021-12-12T21:21:45.748Z",
    status: "requested",
    position: {
      lat: 0,
      lng: 0,
      adress: "string",
      id: 0,
    },
    student: {
      id: 0,
      name: "string",
    },
    teacher: {
      id: 0,
      name: "string",
      rating: 0,
    },
    applications: [],
  },
];
export const HomeTitle = () => {
  return <PageTitle>Kommande Lektioner</PageTitle>;
};

export const Home = () => {
  const { setTopContent } = React.useContext(TopBarContext);
  React.useEffect(() => {
    setTopContent(prev => ({
      ...prev,
      childId: "HomeTitle",
      childProps: {},
      childPos: "bottom",
      flatTop: false,
    }));
    return () => {
      setTopContent(prev => ({
        ...prev,
        childId: null,
        childProps: {},
        childPos: null,
      }));
    };
  }, [setTopContent]);
  return (
    <section className={`${styles.home}`}>
      <Schedule debugData={null}></Schedule>
    </section>
  );
};

export default Home;
