import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import { faCheck, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";

import "./ChooseSubjects.css";
import { TopBarContext } from "student-pages/app";
import { useSubjectsData } from "./subjectsData";

const colortheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
        },
        root: {
          paddingRight: "0",
        },
        input: {
          padding: "7px",
          fontWeight: "500",
          color: "#373737",
          "&::placeholder": {
            color: "#959595",
            opacity: "1",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: {
          color: "#01613B",
          fontWeight: "500",
          marginBottom: "0.8rem",
          fontSize: "1.6rem",
          textAlign: "center",
        },
        subjectHeader: {
          fontFamily: "Roboto",
          color: "#00613B",
          fontWeight: "500",
          marginBottom: "0.8rem",
          fontSize: "1.5rem",
          textAlign: "center",
          textTransform: "capitalize",
          marginLeft: "clamp(1.5rem,6vw,3rem)",
        },
        subject: {
          fontFamily: "Roboto",
          color: "#717171",
          fontWeight: "400",
          fontSize: "1.1rem",
          textAlign: "center",
          textTransform: "capitalize",
          marginTop: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          transitionDuration: "200ms",
          transitionProperty: "opacity",
          "&.Mui-disabled": {
            opacity: "0.75",
          },

          "&.Mui-disabled:hover": {
            backgroundColor: "inherit",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#00613b",
      light: "#99ffd7",
      dark: "#00613b",
    },
  },
});

const Subject = ({ updateChosen, icon, subject, formData }) => {
  const [isActive, setIsActive] = useState(formData.subjects.includes(subject));

  return (
    <ThemeProvider theme={colortheme}>
      <button
        key={subject}
        onClick={() => {
          updateChosen(subject, !isActive);
          setIsActive(!isActive);
        }}
        className={"subject-option " + (isActive ? "active" : "")}
      >
        <div className="subjIcon d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={faCheck} size="lg" className="subjCheck" />
          <FontAwesomeIcon icon={icon} size="5x" />
        </div>
        <Typography variant="subject" className="text-center cap">
          {subject}
        </Typography>
      </button>
    </ThemeProvider>
  );
};
const Category = props => {
  return (
    <div className="subject_category">
      {props.subjects.map((subject, index) => (
        <Subject key={subject} subject={subject} index={index} {...props} />
      ))}
      {/* Fixes padding on the right side of the subject view */}
      {/* Yes it's very ugly, but it does the work */}
      <div style={{ color: "transparent", fontSize: "1px" }}>Padding</div>
    </div>
  );
};
export const SearchBar = ({ search }) => {
  const [typed, setTyped] = React.useState();
  const [validSearch, setValidSearch] = React.useState(true);

  // Colortheme is defined here as it needs to access the value of `validSeach`
  // I'm not sure if the red coloring actually adds to the user experience
  // Maybe it should just be constantly gray instead?
  const colortheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "none",
          },
          root: {
            paddingRight: "0",
          },
          input: {
            padding: "7px",
            fontWeight: "500",
            color: validSearch ? "#373737" : "#df4759",
            "&::placeholder": {
              color: "#959595",
              opacity: "1",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h3: {
            color: "#01613B",
            fontWeight: "500",
            marginBottom: "0.8rem",
            fontSize: "1.6rem",
            textAlign: "center",
          },
          subjectHeader: {
            fontFamily: "Roboto",
            color: "#00613B",
            fontWeight: "500",
            marginBottom: "0.8rem",
            fontSize: "1.5rem",
            textAlign: "center",
            textTransform: "capitalize",
            marginLeft: "clamp(1.5rem,6vw,3rem)",
          },
          subject: {
            fontFamily: "Roboto",
            color: "#717171",
            fontWeight: "400",
            fontSize: "1.1rem",
            textAlign: "center",
            textTransform: "capitalize",
            marginTop: "8px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            transitionDuration: "200ms",
            transitionProperty: "opacity",
            "&.Mui-disabled": {
              opacity: "0.75",
            },

            "&.Mui-disabled:hover": {
              backgroundColor: "inherit",
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#00613b",
        light: "#99ffd7",
        dark: "#00613b",
      },
    },
  });

  const handleType = e => {
    e.preventDefault();
    setTyped(e.target.value);

    try {
      search(e.target.value).then(searchResult => {
        setValidSearch(!!searchResult.length);
      });
    } catch (err) {
      console.log("search", search, " doesn't work");
      console.log(err);
    }
  };

  return (
    <ThemeProvider theme={colortheme}>
      <div className="top_search_parent w-100 d-flex flex-column align-items-center">
        <Typography variant="h3">Vad vill du ha hjälp med?</Typography>
        <TextField
          className={`top_search_bar f3 mb-3 px-3 ${validSearch ? "" : "reject-primary"}`}
          value={typed}
          error={validSearch}
          onChange={handleType}
          rows="1"
          placeholder="Sök ämne..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon className="dark" onClick={() => search(typed)} />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </ThemeProvider>
  );
};

function ChoseSubjects(props) {
  const { formData, setFormData, goToNext } = props;
  const { subjectsData, search } = useSubjectsData();
  const { setTopContent, setHelperText, setHelperTitle, setUseTopPadding } =
    React.useContext(TopBarContext);
  const [lastSubject, setLastSubject] = React.useState(null);

  useEffect(() => {
    setTopContent(prev => ({
      ...prev,
      childId: "SearchBar",
      childProps: { search },
    }));
    return () => setTopContent(null);
  }, [setTopContent, search]);

  useEffect(() => {
    setHelperTitle("Välj ämne");
    setHelperText(
      "Välj ett eller flera ämnen att få lektion för. Om du väljer flera ämnen väljer du och läraren själva hur mycket tid ni spenderar på vardera ämne.",
    );
    setUseTopPadding(false);

    return () => {
      setHelperText(null);
      setHelperTitle(null);
      setUseTopPadding(true);
    };
  }, []);

  const updateChosen = (title, nowChosen) => {
    setFormData(prev => {
      setLastSubject(prev.subjects.slice(-1)[0]);
      let newSubjects = prev.subjects.filter(s => s !== title);

      if (nowChosen) {
        newSubjects.push(title);
      }

      return { ...prev, subjects: newSubjects };
    });
  };

  // Show loading screen while awaiting server response (assumes at least one subject is available)
  if (!subjectsData) {
    const outerNum = 5;
    const innerNum = 10;

    return (
      <div style={{ height: "100%", overflow: "hidden" }}>
        {[...Array(outerNum)].map((e, i) => (
          <div style={{ overflow: "hidden" }} key={e}>
            <Skeleton
              key={String(i)}
              variant="rounded"
              animation="wave"
              width="13rem"
              height="1.8rem"
              className="subject_header_skeleton"
            />
            <div className="scrollparent">
              <div
                className="subject_category"
                style={{ width: "max-content", maxWidth: "max-content" }}
              >
                {[...Array(innerNum)].map((e, j) => (
                  <Skeleton
                    key={`${i} ${j}`}
                    variant="rounded"
                    animation="wave"
                    width="clamp(10rem, 20vw, 15rem)"
                    height="clamp(10rem, 20vw, 15rem)"
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <ThemeProvider theme={colortheme}>
        {/*
                    Fixes the padding on the bottom, if h-100 is removed the padding is lost too.
                    A height of 100% is currently required to center the message displayed
                    when no search results are found.
                */}
        <section
          className={`d-flex flex-column choose-subjects-section pt-2 ${
            subjectsData.data.length ? "" : "h-100"
          }`}
        >
          {/* If subjects have been loaded and there is a matching search (or no search) */}
          {subjectsData && subjectsData.data.length !== 0 && (
            <div>
              {/* All the subjects that are to be displayed */}
              {subjectsData.data.map(subjectData => {
                return (
                  <div key={subjectData.title} className="category_wrapper mt-3">
                    <Typography variant="subjectHeader">{subjectData.title}</Typography>
                    <div className="scrollParent">
                      <Category updateChosen={updateChosen} formData={formData} {...subjectData} />
                    </div>
                  </div>
                );
              })}

              {/*
                                    ONLY used for bottom padding.
                                    The correct padding height is determined by creating an identical menu with an opacity of 0.
                                    This is needed as the real menu has an absolute position and will overlapp with the bottommost subject titles.
                                */}
              {formData.subjects.length ? (
                <div
                  className="position_select_root"
                  style={{ opacity: 0, position: "unset", paddingBottom: "2vh" }}
                >
                  <div className="position_select_size_wrapper">
                    <div className="position_select_panel">
                      <div className="position_select_info">
                        <Typography className="address_text">a</Typography>
                        <Typography className="coordinates_text">a</Typography>
                      </div>
                      <Button onClick={goToNext} className="next_button">
                        Nästa
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ height: "1rem" }} />
              )}
            </div>
          )}

          {/* If subjects have been loaded and there is no matching search */}
          {subjectsData && subjectsData.data.length === 0 && (
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                rowGap: "1.4rem",
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} size="4x" />
              <Typography>Inga ämnen hittades</Typography>
            </div>
          )}
        </section>

        {/* TODO: Separate into custom component */}
        <div
          className="position_select_root"
          style={{
            bottom: formData.subjects.length ? "2%" : "-20%",
            display: formData.subjects.length ? "flex" : "flex",
          }}
        >
          <div className="position_select_size_wrapper">
            <div className="position_select_panel">
              <div className="position_select_info">
                <Typography className="address_text">
                  {formData.subjects.slice(-1)[0] || lastSubject}
                </Typography>
                <Typography className="coordinates_text">
                  Totalt {formData.subjects.length ? formData.subjects.length : 1}{" "}
                  {formData.subjects.length <= 1 ? "vald kurs" : "valda kurser"}
                </Typography>
              </div>
              <Button onClick={goToNext} className="next_button">
                Nästa
              </Button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default ChoseSubjects;
