import React from "react";

import Schedule from "components/LessonDisplay/schedule/teacherSchedule";

function Home() {
  return (
    <section className="home-section">
      <h1 className="f2 text-center">Detta är home page för lärare</h1>
      <Schedule></Schedule>
    </section>
  );
}

export default Home;

// export const tmpLessons = [
//     {
//         teacher: {
//             name: "KNut",
//         },
//         student: {
//             name: 'Berto',
//         },
//         start_time: "2021-07-12T12:15:23Z",
//         length: "1h",
//         date: "12 juni",
//         id: 1
//     },
//     {
//         teacher: {
//             name: "KNut",
//         },
//         student: {
//             name: 'Berto',
//         },
//         start_time: "2021-07-12T12:15:23Z",
//         length: "1h",
//         date: "12 juni",
//         id: 1
//     },
//     {
//         teacher: {
//             name: "KNut",
//         },
//         student: {
//             name: 'Berto',
//         },
//         start_time: "2021-07-12T12:15:23Z",
//         length: "1h",
//         date: "12 juni",
//         id: 1
//     },
// ];
