import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const methods = {
  SWISH: "Swish",
  CREDIT_CARD: "Kreditkort",
  KLARNA: "Klarna (Bank transaktion)",
};

export function PaymentMethods() {
  return (
    <section>
      <h1 className="f2 mb-4 text-center">Betalningsmetod</h1>
      {Object.values(methods).map(val => {
        return (
          <div key={val}>
            <div className="f3 px-2 d-flex justify-content-between">
              <p>{val}</p>
              <FontAwesomeIcon className="mx-5" icon={faArrowRight}></FontAwesomeIcon>
            </div>
            <hr />
          </div>
        );
      })}
    </section>
  );
}

export default PaymentMethods;
