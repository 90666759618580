import * as anim from "components/Animation/animation.js";
import { Box, Button, Container, Link, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { LoginData, login } from "api/user/user";
import { requestNotificationPermissions } from "services/notifications";
import { validate, validateRules } from "../../../utils/formValidation";
import NotificationModal from "components/NotificationModal/NotificationModal";
import React from "react";
import styles from "./Login.module.css";

type LoginProps = {
  refreshData: () => void;
  setSigned_in: (signed_in: boolean) => void;
  setPage: (page: number) => void;
};

const Login: React.FC<LoginProps> = ({ refreshData, setSigned_in, setPage }) => {
  const [showAnimation, setShowAnimation] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = async (values: LoginData) => {
    requestNotificationPermissions();
    const res = await login(values);

    if (!res.success) {
      setErrorMessage(res.message);
    } else {
      let counter = 0;
      const refresh = () => {
        try {
          refreshData();
        } catch (err) {
          if (counter < 10) {
            counter += 1;
            setTimeout(refresh, 100);
          }
        }
      };
      localStorage.setItem("access_token", res.data["access_token"]);
      setSigned_in(true);
      refresh();
    }
  };

  // React.useEffect(() => {
  //   const t = setTimeout(() => {
  //     setShowAnimation(false);
  //   }, 5000);
  // }, []);

  if (showAnimation) return <anim.Animation></anim.Animation>;

  return (
    <div className={styles.root}>
      <Typography variant="h4">GradeLift</Typography>
      <NotificationModal
        show={!!errorMessage}
        message={errorMessage}
        type="error"
        onClose={() => setErrorMessage("")}
      />
      <Container>
        <Form
          onSubmit={handleSubmit}
          validate={validate({
            email: validateRules.email,
            password: validateRules.password,
          })}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <Box marginBottom={2}>
                  <Field name="email">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        required
                      />
                    )}
                  </Field>
                </Box>
                <Box marginBottom={5}>
                  <Field name="password">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        required
                      />
                    )}
                  </Field>
                </Box>
              </div>
              <Box marginBottom={3} display="flex" justifyContent="center">
                <Button type="submit" variant="contained" color="primary">
                  Logga in
                </Button>
              </Box>
            </form>
          )}
        />
      </Container>
      <Typography component={"span"} variant="body2">
        Don't have an account?{" "}
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            setPage(1);
          }}
        >
          Sign up now
        </Link>
      </Typography>
    </div>
  );
};

export default Login;
