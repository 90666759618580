import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TopBarContext } from "student-pages/app";
import { faBookmark, faHome, faUniversity, faUser } from "@fortawesome/free-solid-svg-icons";

import "./student-nav.css";

// Define constants for the routes of the app
export const studentLinks = {
  HOME: "/home",
  BOOK: "/book",
  HISTORY: "/history",
  PROFILE: "/profile",
};

// Define the tabs that will be displayed in the Navigation component
const tabs = [
  {
    route: studentLinks.HOME,
    icon: faHome,
    label: "Home",
  },
  {
    route: studentLinks.BOOK,
    icon: faBookmark,
    label: "Book Time",
  },
  {
    route: studentLinks.HISTORY,
    icon: faUniversity,
    label: "History",
  },
  {
    route: studentLinks.PROFILE,
    icon: faUser,
    label: "Profile",
  },
];

// The Navigation component is a functional component that displays a navbar with a series of tabs at the bottom of the screen
const Navigation = () => {
  const { setStep, setTotalSteps } = React.useContext(TopBarContext);

  const resetPage = () => {
    // Resets all page specific values to their initial values
    setStep(0);
    setTotalSteps(0);
  };

  return (
    <div className="student-nav-parent">
      <nav className="navbar fixt-bottom navbar-light bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className="d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className="nav-link bottom-nav-link"
                  activeClassName="active"
                  onClick={resetPage}
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center pt-1">
                      <FontAwesomeIcon size="lg" icon={tab.icon} />
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default Navigation;
