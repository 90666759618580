import React, { useState } from "react";

import { Container } from "@mui/material";
import { useFetch } from "hooks/fetch-hooks";
import FinishedLesson from "components/LessonDisplay/FinishedLesson";
import Loading from "components/Loading";
import NoLessons from "./NoLessons";
import PageTitle from "components/navigation/PageTitle";

const History = () => {
  const { data: finishedLessons, isLoading } = useFetch("/api/lesson/student/history", {
    authorize: true,
  });
  const [selectedLesson, setSelectedLesson] = useState(-1);

  if (isLoading) return <Loading />;
  return (
    <Container sx={{ height: "100%" }}>
      <PageTitle>Mina Tidigare Lektioner</PageTitle>

      {finishedLessons?.length === 0 && <NoLessons />}
      {finishedLessons?.map(lesson => (
        <FinishedLesson key={lesson.id} lesson={lesson} onClick={() => {}} />
      ))}
    </Container>
  );
};
export default History;

/*function History() {
    const [data, setData] = React.useState(null);
    const [selection, setSelection] = React.useState(null);
    const [editing, setEditing] = React.useState(false);

    const {signed_in, setSigned_in} = useLogin()

    React.useEffect(() => {
        async function fetchData() {
            const res = await fetch('/api/lesson', { method: 'GET', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } });
            if (res.ok) { res.json().then(r => setData(r)).catch(err => alert(err)); }
            else setSigned_in(false);
        }
        fetchData();
    }, [selection, signed_in, editing]);
    if (signed_in == null) return <Loading />;
    if (signed_in == false) return <Auth setSigned_in={setSigned_in} />;
    return <DisplayBookings data={data} selection={selection} setSelection={setSelection} editing={editing} setEditing={setEditing} />;
}

export default History*/
