import { Box, Button, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { LoginContext } from "app";
import { updateProfile } from "api/user/user";
import { useNavigate } from "react-router-dom";
import { validate, validateRules } from "../../../utils/formValidation";
import NotificationModal from "components/NotificationModal";
import React from "react";
import styles from "./Profile.module.css";
import withLoadingScreen from "hocs/WithLoadingScreen";

const ProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const { profile, setProfile } = React.useContext(LoginContext);
  const [notification, setNotification] = React.useState({ text: "", type: null });

  const renderField = (name: string, label: string) => (
    <Box marginY={2}>
      <Field name={name} initialValue={profile[name]}>
        {({ input, meta }) => (
          <TextField
            {...input}
            label={label}
            fullWidth
            variant="standard"
            required
            error={meta.touched && meta.error ? true : false}
            helperText={meta.touched && meta.error ? meta.error : null}
          />
        )}
      </Field>
    </Box>
  );

  const handleSubmit = async values => {
    const token = localStorage.getItem("access_token");
    const response = await updateProfile(values, token);

    if (!response.success) {
      setNotification({ text: response.message, type: "error" });
    } else {
      setProfile({
        ...profile,
        ...response.data,
      });
      setNotification({ text: "Dina uppgifter har uppdaterats", type: "success" });
    }
  };

  if (!profile) return null;

  return (
    <div>
      <NotificationModal
        show={!!notification.text}
        message={notification.text}
        type={notification.type ?? ("success" as "success" | "error" | "warning" | "info")}
        onClose={() => setNotification({ text: "", type: null })}
      />
      <Box className={styles.root}>
        <div className={styles.inputContainer}>
          <Form
            onSubmit={handleSubmit}
            validate={validate(
              {
                name: validateRules.name,
                phonenumber: validateRules.phonenumber,
                birthyear: validateRules.birthyear,
              },
              { birthyear: true },
            )}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h4" align="center">
                  Mina uppgifter
                </Typography>
                {renderField("name", "För- och efternamn")}
                {renderField("phonenumber", "Telefonnummer")}
                {renderField("default_address", "Address")}
                {renderField("birthyear", "Födelseår")}
                {renderField("email", "E-post")}
                <Box marginTop={7} display="flex" justifyContent="flex-end" columnGap={1}>
                  <Button color="primary" onClick={() => navigate("..")}>
                    Tillbaka
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Uppdatera profil
                  </Button>
                </Box>
              </form>
            )}
          />
        </div>
      </Box>
    </div>
  );
};

export default withLoadingScreen(ProfileForm);
