import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAppleAlt,
  faChalkboardTeacher,
  faCookie,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";

import "./admin-nav.css";

export const adminLinks = {
  AUTH: "/authorize",
  PROMOS: "/promoes",
  BANANAS: "/bananas",
  APPLES: "/apples",
};

const tabs = [
  {
    route: adminLinks.AUTH,
    icon: faChalkboardTeacher,
    label: "Auth",
  },
  {
    route: adminLinks.PROMOS,
    icon: faPercent,
    label: "Promo",
  },
  {
    route: adminLinks.APPLES,
    icon: faAppleAlt,
    label: "Äpplen",
  },
  {
    route: adminLinks.BANANAS,
    icon: faCookie,
    label: "bananer",
  },
];

const Navigation = props => {
  return (
    <div className="admin-nav-parent">
      {/* Bottom Bar*/}
      <nav className="navbar fixed-bottom navbar-light bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className="d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className="nav-link bottom-nav-link"
                  activeClassName="active"
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center">
                      <FontAwesomeIcon size="lg" icon={tab.icon} />
                    </div>
                    {/* <div className="bottom-tab-label">{tab.label}</div> */}
                  </div>
                </NavLink>
              </NavItem>
            ))}
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default Navigation;
