import React, { useEffect } from "react";

import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { TopBarContext } from "student-pages/app";
import { dateToSwe } from "services/formats/dates";
import styles from "./confirm.module.css";
//import styles from './confirm.module.css'

function Confirm({ formData, setFormData, goToNext }) {
  const gutter = 1;
  const { setHelperText, setHelperTitle } = React.useContext(TopBarContext);
  const handleComment = e => {
    e.preventDefault();
    setFormData({
      ...formData,
      note: e.target.value,
    });
  };

  useEffect(_ => {
    setHelperTitle("Bekräfta bokningen");
    setHelperText(
      "Detaljer om lektionen. Här kan du se att allt stämmer innan du beställer lektionen.",
    );
    return _ => {
      setHelperTitle(null);
      setHelperText(null);
    };
  }, []);

  return (
    <section className={`${styles.confirm_section} text-center`}>
      <h1 className={`${styles.title} f2`}>Orderbekräftelse</h1>
      <div className={`${styles.group_1} mt-2 px-5 form-group f3`}>
        {" "}
        {/* Nivå 1  - Formulär group*/}
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol className={`mt-${gutter} d-flex justify-content-start`} size="4">
              <span>Ämnen: </span>
            </MDBCol>
            <MDBCol className={`mt-${gutter} ${styles.info} d-flex justify-content-end`} size="8">
              {" "}
              <span>{[...formData.subjects].join(", ")}</span>{" "}
            </MDBCol>
            <hr />

            <MDBCol className={`mt-${gutter} d-flex justify-content-start`} size="4">
              <span>Datum:</span>
            </MDBCol>
            <MDBCol className={`mt-${gutter} ${styles.info} d-flex justify-content-end`} size="8">
              {" "}
              <span>{dateToSwe(formData.date)}</span>{" "}
            </MDBCol>
            <hr />

            <MDBCol className={`mt-${gutter} d-flex justify-content-start`} size="4">
              <span>Start Mellan:</span>
            </MDBCol>
            <MDBCol className={`mt-${gutter} ${styles.info} d-flex justify-content-end`} size="8">
              {" "}
              <span>{`${formData.earliest_potential_start.toLocaleTimeString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
              })} - ${formData.latest_potential_start.toLocaleTimeString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
              })}`}</span>{" "}
            </MDBCol>
            <hr />

            <MDBCol className={`mt-${gutter} d-flex justify-content-start`} size="4">
              <span>Plats:</span>
            </MDBCol>
            <MDBCol className={`mt-${gutter} ${styles.info} d-flex justify-content-end`} size="8">
              {" "}
              <span>{formData.position.adress}</span>{" "}
            </MDBCol>
            <hr />

            <MDBCol className={`mt-${gutter} d-flex justify-content-start`} size="4">
              <span>Längd:</span>
            </MDBCol>
            <MDBCol className={`mt-${gutter} ${styles.info} d-flex justify-content-end`} size="8">
              {" "}
              <span>{`${formData.length} min`}</span>{" "}
            </MDBCol>
            <hr />
          </MDBRow>
        </MDBContainer>
      </div>

      <div className={`${styles.group_2} f2 form-group mt-3 py-5 px-4`}>
        <textarea
          className="f3 mb-3 p-3"
          value={formData.note}
          onChange={handleComment}
          rows="4"
          placeholder="Kommentar"
        ></textarea>
        <MDBContainer fluid>
          <MDBRow className={""}>
            {/* <MDBCol size="12" className="text-center my-2">Övriga Kommentarer:</MDBCol> */}
            <MDBCol size="4" className="d-flex justify-content-start">
              <span className={`${styles.green_txt}`}>Rabattkod:</span>
            </MDBCol>
            <MDBCol size="8" className="d-flex justify-content-end">
              <input type="text" placeholder="Promo code..." />
            </MDBCol>
            <hr />

            <MDBCol size="4" className="d-flex justify-content-start">
              <span>Totalt:</span>
            </MDBCol>
            <MDBCol size="8" className="d-flex justify-content-end">
              <span className={`${styles.green_txt}`}>53kr</span>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <button className={`btn mt-5 f2 w2 ${styles.confirm_btn}`} onClick={goToNext}>
          Bekräfta
        </button>
      </div>
      {/* <div>
                {
                    !showPromo &&
                    <button className="btn promo accept-theme" onClick={()=>setShowPromo(prev=>!prev)}>
                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Lägg till rabattkod
                    </button>
                }

                {showPromo && <input type="text" className="" placeholder="Promo code..."/>}
            </div> */}
      {/* <div >
                    <div className="wrapper">
                        <div></div>
                        <div>{[...formData.subjects].join(', ')}</div>
                    </div>
                </div>
                <div className={styles.list_item}>
                    <div className="wrapper">
                        <div>Tid</div>
                        <div>14:00 - 21:00</div>
                    </div>
                </div>
                <div className={styles.list_item}>
                    <div className="wrapper">
                        <div>Datum</div>
                        <div>16 juni 2021</div>
                    </div>
                </div>
                <div className={styles.list_item}>
                    <div className="wrapper">
                        <div>Plats</div>
                        <div>Stenbocksvägen 10, Tensta, Stockholm, Sverige</div>
                    </div>
                </div>
                <div className={styles.list_item}>
                    <div className="wrapper">
                        <div>Övriga kommentarer</div>
                        <div>Ta med ett gott humör och lite chips :)</div>
                    </div>
                </div>
            </div> */}
    </section>
  );
}

export default Confirm;
