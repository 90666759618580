import { Box } from "@mui/system";
import { Container, Link, Typography } from "@mui/material";
import { LoginContext } from "app";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLogin } from "hooks/fetch-hooks";
import Auth from "components/auth";
import Edit from "./edit-profile";
import Loading from "components/Loading";
import PageTitle from "components/navigation/PageTitle";
import React from "react";

function ProfileInfo() {
  const navigate = useNavigate();

  return (
    <section>
      <PageTitle>Inställningar</PageTitle>
      <Container maxWidth="md">
        <Box sx={{ my: 5 }}>
          {pages.map(page => {
            return (
              <Box key={page.title} py={2}>
                <Typography variant="body1" align="center">
                  <Link onClick={() => navigate(page.link)} variant="passive">
                    {page.title}
                  </Link>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Container>
    </section>
  );
}

const Temp = () => {
  return <Typography variant="h4">This page is under construction...</Typography>;
};

const pages = [
  {
    title: "Uppdatera profil",
    link: "edit-profile",
    component: Edit,
  },
  {
    title: "Favoriter",
    link: "favorites",
    component: Temp,
  },
  {
    title: "Bjud in vänner",
    link: "send-invite",
    component: Temp,
  },
  {
    title: "Inställningar",
    link: "settings",
    component: Temp,
  },
  {
    title: "Hjälp",
    link: "help",
    component: Temp,
  },
  {
    title: "Användarvillkor",
    link: "terms",
    component: Temp,
  },
];

function Main() {
  const { signed_in, setSigned_in } = useLogin();

  if (signed_in === null) return <Loading></Loading>;
  if (signed_in === false) return <Auth setSigned_in={setSigned_in}></Auth>;

  return (
    <Routes>
      {pages.map(page => (
        <Route path={page.link} element={<page.component />} key={page.title}></Route>
      ))}
      <Route path="/" element={<ProfileInfo />}></Route>
    </Routes>
  );
}
export default Main;
