import React, { useEffect, useState } from "react";

import { CustomRating } from "components/RateLesson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useFetch } from "hooks/fetch-hooks";
import FinishedLesson from "components/LessonDisplay/FinishedLesson";
import Loading from "components/Loading";

const History = () => {
  const { data: finishedLessons, isLoading } = useFetch("/api/lesson/student/history", {
    authorize: true,
  });
  const [selectedLesson, setSelectedLesson] = useState(-1);

  if (isLoading) return <Loading></Loading>;
  return (
    <section className="history">
      <h1 className="f2 text-center cap">Mina Tidigare Lektioner</h1>
      {finishedLessons?.map(lesson => (
        <FinishedLesson key={lesson.id} lesson={lesson} onClick={() => {}}></FinishedLesson>
      ))}
    </section>
  );
};
export default History;
