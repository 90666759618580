import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import { useFetch } from "hooks/fetch-hooks";
import React from "react";

import { customPost } from "services/api/promises";
import Loading from "components/Loading";
import styles from "./styles.module.css";

function Authorize() {
  const { data, isLoading } = useFetch("/api/lesson/student/subjects-list", {});
  const [teacherData, setTeacherData] = React.useState(null);
  const [added, updateAdded] = React.useState([]);

  const updateData = async email => {
    const res = await customPost("/api/admin/get-user/email", { email }, { authorize: true });
    const parsed = await res.json();
    setTeacherData(parsed);
  };

  const toggleSubj = subj => {
    if (subj in added) {
      updateAdded(prev => {
        return prev.filter(el => el !== subj);
      });
    } else {
      updateAdded(prev => [...prev, subj]);
    }
  };
  const handleChange = e => {
    e.preventDefault();
    if (e.target.name !== "email") return;
    updateData(e.target.value);
  };
  const handleSubmit = () => {
    if (teacherData && "id" in teacherData) {
      const key = "ja";

      if (!window.confirm(`vill du göra ${teacherData.name} till teacher.`)) return;
      customPost(
        `/api/admin/validate-teacher/${teacherData.id}`,
        { authorized_teaching_subjects: added },
        {},
      ).then(r => {
        r.text().then(text => {
          alert(`status ${r.status} ${text}`);
        });
      });
    } else {
      alert("Ingen användare med den mailen.");
    }
  };
  console.log(data);
  if (!isLoading === false) return <Loading></Loading>;
  return (
    <div className={`${styles.container}`}>
      <h1 className="f2 text-center">Auktorisera en lärare via formmuläret</h1>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="6 col-centered">
            {teacherData ? (
              <>
                <p className="mt-3">Namn: {teacherData.name}</p>
                <p>Roll: {teacherData.role}</p>
                {/* <p>teacherData.</p> */}
              </>
            ) : (
              <>
                <p>Ingen Matchning</p>
              </>
            )}
          </MDBCol>
          <MDBCol md="6 col-centered">
            <form>
              <div>
                <MDBInput
                  label="Email"
                  icon="envelope"
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  name="email"
                  onChange={handleChange}
                />
              </div>

              <ul className="checks">
                {Object.keys(data?.subjects || {}).map(category => {
                  return (
                    <>
                      {data.subjects[category].map(subj => {
                        if (typeof subj !== "string") return null;
                        return (
                          <li key={subj} onClick={() => toggleSubj(subj)}>
                            <input
                              type="checkbox"
                              name="amnen[]"
                              value={subj}
                              id={subj}
                              className="me-2"
                            />
                            <label htmlFor={subj}>{subj}</label>
                          </li>
                        );
                      })}
                    </>
                  );
                })}
                {/* <li><input type="checkbox" name="amnen[]" value="Svenska" id="sv" class="me-2" /><label for="ma">Svenska</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Engelska" id="en" class="me-2" /><label for="ma">Engelska</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Fysik" id="fy" class="me-2" /><label for="ma">Fysik</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Kemi" id="ke" class="me-2" /><label for="ma">Kemi</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Biologi" id="bi" class="me-2" /><label for="ma">Biologi</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Franska" id="fra" class="me-2" /><label for="ma">Franska</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Spanska" id="spa" class="me-2" /><label for="ma">Spanska</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Tyska" id="tys" class="me-2" /><label for="ba">Tyska</label></li>
                                    <li><input type="checkbox" name="amnen[]" value="Övrigt" id="sh" class="me-2" /><label for="ma">Övrigt</label></li> */}
              </ul>
              <div className="text-center">
                <MDBBtn onClick={handleSubmit}>Auktorisera lärare</MDBBtn>
              </div>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default Authorize;
