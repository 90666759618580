import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import svLocale from "date-fns/locale/sv";

import { customPost } from "services/api/promises";
import { dateStrToShortSwe, timeStrToSwe } from "services/formats/dates";
import styles from "./requested.module.css";

function Small({ lesson, onClick, removeSelf }) {
  return (
    <article className={styles.small} onClick={onClick}>
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="6">
            <p className="f3 mb-2">{lesson.student.name}</p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4 mb-2">
              Start mellan <br /> {timeStrToSwe(lesson.earliest_potential_start)} och{" "}
              {timeStrToSwe(lesson.latest_potential_start)}
            </p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4">{dateStrToShortSwe(lesson.earliest_potential_start)}</p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4">{lesson.length} min</p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </article>
  );
}
function FullInfo({ lesson, minimize, removeSelf }) {
  const [arrival, setArrival] = useState(new Date(lesson.earliest_potential_start));
  const apply = () => {
    customPost(`/api/lesson/teacher/apply/${lesson.id}`, { arrival }, {})
      .then(r => removeSelf())
      .catch(err => {
        alert(err);
      });
  };
  return (
    <article className={`${styles.fullInfo} d-flex flex-column align-items-center`}>
      <span className={`${styles.minimizer}`} onClick={minimize}>
        <FontAwesomeIcon icon={faTimesCircle} size="sm"></FontAwesomeIcon>
      </span>
      <h2 className="f2 mt-3">Begär det här uppdraget</h2>
      <MDBContainer fluid className={`my-3 ${styles.info}`}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="6">
            <p className="f3 mb-2">{lesson.student.name}</p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4 mb-2">
              Start mellan <br /> {timeStrToSwe(lesson.earliest_potential_start)} och{" "}
              {timeStrToSwe(lesson.latest_potential_start)}
            </p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4 mb-2">{dateStrToShortSwe(lesson.earliest_potential_start)}</p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4 mb-2">{lesson.length} min</p>
          </MDBCol>
          <MDBCol size="6">
            <p className="f4 mb-2">{lesson.subjects.join(", ")}</p>
          </MDBCol>
          <MDBCol size="6">
            {lesson.note && <p className="f4 mb-2">Kommentar: {lesson.note}</p>}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <p className="f4">Jag kommer klockan </p>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={svLocale}>
        <TimePicker
          value={arrival}
          onChange={newValue => setArrival(newValue)}
          minTime={new Date(lesson.earliest_potential_start)}
          maxTime={new Date(lesson.latest_potential_start)}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              className={`${styles.arrivalInp} mb-3`}
              variant="standard"
            ></TextField>
          )}
        />
      </LocalizationProvider>
      <button className="accept-theme btn" onClick={apply}>
        Begär
      </button>
    </article>
  );
}

function RequestedLesson({ lesson, removeSelf = () => {} }) {
  const [expand, setExpand] = useState(false);
  if (expand)
    return (
      <FullInfo
        lesson={lesson}
        minimize={() => setExpand(false)}
        removeSelf={removeSelf}
      ></FullInfo>
    );
  return <Small lesson={lesson} onClick={() => setExpand(true)} removeSelf={removeSelf}></Small>;
}

export default RequestedLesson;
