import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { SignupData, signup } from "api/user/user";
import { validate, validateRules } from "../../../utils/formValidation";
import NotificationModal from "components/NotificationModal/NotificationModal";
import React from "react";
import styles from "./Registration.module.css";

type RegistrationProps = {
  setPage: (page: number) => void;
};

type CustomTextFieldProps = {
  name: string;
  label: string;
  type: string;
  required?: boolean;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  type,
  required = false,
}) => (
  <Box marginBottom={2}>
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          label={label}
          type={type}
          fullWidth
          variant="standard"
          required={required}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const allowedPattern = validateRules[name]?.allowed;
            if (allowedPattern !== undefined && !allowedPattern.test(event.target.value)) {
              event.preventDefault();
            } else {
              input.onChange(event);
            }
          }}
        />
      )}
    </Field>
  </Box>
);

const Registration: React.FC<RegistrationProps> = ({ setPage }) => {
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = async (values: SignupData) => {
    const res = await signup(values);
    if (!res.success) {
      setErrorMessage(res.message);
    } else {
      setPage(0);
    }
  };

  return (
    <div className={styles.root}>
      <Box marginBottom={2}>
        <Typography variant="h4">Skapa konto</Typography>
      </Box>
      <NotificationModal
        show={!!errorMessage}
        message={errorMessage}
        type="error"
        onClose={() => setErrorMessage("")}
      />
      <Container>
        <Form
          onSubmit={handleSubmit}
          validate={validate(
            {
              name: validateRules.name,
              email: validateRules.email,
              password: validateRules.password,
              phonenumber: validateRules.phonenumber,
              birthyear: validateRules.birthyear,
            },
            { birthyear: true },
          )}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <div>
                  <CustomTextField name="name" label="För- och efternamn" type="text" required />
                  <CustomTextField name="phonenumber" label="Telefonnummer" type="tel" required />
                  <CustomTextField name="email" label="Email" type="email" required />
                  <CustomTextField name="password" label="Lösenord" type="password" required />
                  <CustomTextField name="default_address" label="Address" type="text" />
                  <CustomTextField name="birthyear" label="Födelseår" type="number" />
                </div>
                <Box marginTop={5} display="flex" justifyContent="flex-end" columnGap={1}>
                  <Button color="primary" onClick={() => setPage(0)}>
                    Tillbaka
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Registrera
                  </Button>
                </Box>
              </div>
            </form>
          )}
        />
      </Container>
    </div>
  );
};

export default Registration;
